import addSeconds from 'date-fns/add_seconds'
import format from './localeFormat'

const formatSeconds = seconds => {
  const time = addSeconds(new Date().setHours(0, 0, 0, 0), seconds || 0)
  return time.getHours() > 0 ? format(time, 'HH:mm:ss') : format(time, 'mm:ss')
}

export const secondsToNorwegianMinutesPresicion = seconds => {
  const time = addSeconds(new Date().setHours(0, 0, 0, 0), seconds || 0)
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const secondsFromDateTime = time.getSeconds()

  const hoursPart = hours > 0 ? `${hours} t ` : ''
  const minutesPart = minutes > 0 ? `${minutes} min ` : ''
  const secondsPart = hours === 0 && minutes === 0 && secondsFromDateTime > 0 ? `${secondsFromDateTime} s` : ''

  return `${hoursPart}${minutesPart}${secondsPart}`.trim()
}

export default formatSeconds
