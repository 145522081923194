import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'
import useShallowSelector from '../../../components/hooks/useShallowSelector'
import toSrcSet from '../helpers/toSrcSet'
import shouldReverseSeriesSort from '../helpers/shouldReverseSeriesSort'
import SORTS from '../helpers/sorts'
import SERIES_TYPES from '../helpers/seriesTypes'
import { catalogPodcastLinkToPath, catalogSeriesLinkToPath } from '../../../common/preferredSeries'

const emptyObject = {}
const emptyArray = []

export const seriesRootSelector = state => {
  return state.series
}

export const useSeriesRoot = () => {
  const seriesRoot = useShallowSelector(seriesRootSelector)
  return seriesRoot
}

const seriesIsLoadingSelector = createSelector([seriesRootSelector], series => {
  return series.loading
})

export const useSeriesIsLoading = () => {
  const isLoading = useSelector(seriesIsLoadingSelector)
  return isLoading
}

const contentTypeSelector = createSelector([seriesRootSelector], seriesRoot => seriesRoot.contentType)

export const useContentType = () => {
  const contentType = useSelector(contentTypeSelector)
  return contentType
}

const seriesVariantHrefSelector = createSelector(
  [seriesRootSelector, contentTypeSelector],
  (seriesRoot, contentType) => {
    const variant = contentType === 'podcast' ? 'series' : 'podcast'
    const seriesVariantHref = _get(seriesRoot, `_links.${variant}.href`)
    return seriesVariantHref
  }
)

export const useSeriesVariantHref = () => {
  const seriesVariantHref = useSelector(seriesVariantHrefSelector)
  return seriesVariantHref
}

export const seriesTypeSelector = createSelector([seriesRootSelector], seriesRoot => seriesRoot.seriesType)

export const useSeriesType = () => {
  const seriesType = useSelector(seriesTypeSelector)
  return seriesType
}

const seriesSelector = createSelector([seriesRootSelector], seriesRoot => {
  return seriesRoot.series
})

const seriesIdSelector = createSelector([seriesSelector], series => {
  return _get(series, 'id')
})

export const useSeriesId = () => {
  const seriesId = useShallowSelector(seriesIdSelector)
  return seriesId
}

const heroImageSelector = createSelector([seriesSelector], ({ backdropImage }) => {
  if (!backdropImage) return emptyObject

  const { url } = backdropImage.find(({ width }) => width > 960) || {}
  const srcSet = toSrcSet(backdropImage)
  return { src: url, srcSet }
})

export const useHeroImage = () => {
  const heroBackdropImage = useShallowSelector(heroImageSelector)
  return heroBackdropImage
}

const seriesImageSquareRawSelector = createSelector([seriesSelector], series => series.squareImage)

export const useSeriesImageSquareRaw = () => {
  const seriesImageSquare = useShallowSelector(seriesImageSquareRawSelector)
  return seriesImageSquare
}

const seriesImageRawSelector = createSelector([seriesSelector], series => series.image)

export const useSeriesImageRaw = () => {
  const seriesImagesRaw = useShallowSelector(seriesImageRawSelector)
  return seriesImagesRaw
}

const seriesImageSelector = createSelector(
  [seriesImageSquareRawSelector, seriesImageRawSelector],
  (squareImage, image) => {
    const imageToUse = squareImage || image
    if (!imageToUse) return emptyObject

    const { url } = imageToUse.find(({ width }) => width >= 260) || {}
    const srcSet = toSrcSet(imageToUse)
    return { src: url, srcSet }
  }
)

export const useSeriesImage = () => {
  const seriesImage = useShallowSelector(seriesImageSelector)
  return seriesImage
}

const seriesTitlesSelector = createSelector([seriesSelector], ({ titles = emptyObject }) => titles)

export const useSeriesTitles = () => {
  const seriesTitles = useShallowSelector(seriesTitlesSelector)
  return seriesTitles
}

const seriesTitleSelector = createSelector([seriesTitlesSelector], ({ title } = emptyObject) => title)

export const useSeriesTitle = () => {
  const seriesTitle = useSelector(seriesTitleSelector)
  return seriesTitle
}

const seriesSubtitleSelector = createSelector([seriesTitlesSelector], ({ subtitle } = emptyObject) => subtitle)

export const useSeriesSubtitle = () => {
  const seriesSubtitle = useSelector(seriesSubtitleSelector)
  return seriesSubtitle
}

export const userSortSelector = createSelector([seriesSelector], series => series.sort)

export const useUserSort = () => {
  const sort = useSelector(userSortSelector)
  return sort
}

export const shouldReverseSeriesSortSelector = createSelector(
  [seriesTypeSelector, userSortSelector],
  (seriesType, seriesSort) => {
    return shouldReverseSeriesSort(seriesType, seriesSort)
  }
)

export const useShouldReverseSeriesSort = () => {
  const shouldReverseSeriesSort = useSelector(shouldReverseSeriesSortSelector)
  return shouldReverseSeriesSort
}

const seasonsRootSelector = createSelector([seriesRootSelector], ({ seasons }) => seasons)

const unsortedSeasonsSelector = createSelector([seasonsRootSelector], seasonsRoot => {
  const seasons = _get(seasonsRoot, 'seasons', emptyArray)
  return seasons
})

const sortedSeasonsSelector = createSelector(
  [unsortedSeasonsSelector, seriesTypeSelector, userSortSelector],
  (seasons, seriesType, userSort) => {
    if (seriesType === SERIES_TYPES.STANDARD) {
      return userSort === SORTS.ASC
        ? [...seasons, { name: null, title: 'Eldste episoder', href: null }].reverse()
        : [{ name: null, title: 'Nyeste episoder', href: null }, ...seasons]
    }
    return seasons
  }
)

export const useSortedSeasons = () => {
  const seasons = useShallowSelector(sortedSeasonsSelector)
  return seasons
}

const seriesEmbeddedSelector = createSelector([seriesRootSelector], seriesRoot =>
  _get(seriesRoot, '_embedded', emptyObject)
)

const seriesEmbeddedSeasonsSelector = createSelector([seriesEmbeddedSelector], _embedded => {
  const seasons = _get(_embedded, 'seasons', emptyArray)
  if (seasons.length > 0) {
    return seasons.map(({ image = [], ...season }) => {
      const foundImage = image.find(({ width }) => width > 278) || {}
      const imageUrl = foundImage.url
      const srcSet = toSrcSet(image)
      return { ...season, imageUrl, srcSet }
    })
  }
  return seasons
})

export const useSeriesEmbeddedSeasons = () => {
  const embeddedSeasons = useShallowSelector(seriesEmbeddedSeasonsSelector)
  return embeddedSeasons
}

const seasonDisplayDisplayTypeSelector = createSelector(
  [seriesRootSelector],
  ({ seasonDisplayType }) => seasonDisplayType
)

export const useSeasonDisplayType = () => {
  const seasonDisplayType = useSelector(seasonDisplayDisplayTypeSelector)
  return seasonDisplayType
}

const highlightedEpisodeHrefTemplateSelector = createSelector([seriesRootSelector], seriesRoot => {
  return _get(seriesRoot, '_links.highlightedEpisode.href')
})

export const useHighlightedEpisodeHrefTemplate = () => {
  const hrefTemplate = useSelector(highlightedEpisodeHrefTemplateSelector)
  return hrefTemplate
}

const extraMaterialHrefSelector = createSelector([seriesRootSelector], seriesRoot => {
  const extraMaterialHref = seriesRoot?._links?.extraMaterial?.href
  return extraMaterialHref
})

export const useExtraMaterialHref = () => {
  const extraMaterialHref = useSelector(extraMaterialHrefSelector)
  return extraMaterialHref
}

export const useHasExtraMaterial = () => {
  const extraMaterialHref = useSelector(extraMaterialHrefSelector)
  return !!extraMaterialHref
}

const seriesSubmissionsSelector = createSelector([seriesRootSelector], ({ submissions }) => submissions)

export const useSeriesSubmissionsSettings = () => {
  const settings = useSelector(seriesSubmissionsSelector)
  return settings
}

const preferredSeriesHrefTemplateSelector = createSelector(
  [seriesRootSelector, contentTypeSelector],
  (seriesRoot, contentType) => {
    if (contentType === 'podcast') {
      return catalogSeriesLinkToPath(seriesRoot._links?.preferred?.href || '')
    } else {
      return catalogPodcastLinkToPath(seriesRoot._links?.preferred?.href || '')
    }
  }
)

/**
 * Gets the preferred link (`seriesRoot._links.preferred`) to a catalog series/podcast and returns the corresponding path to the series/podcast.
 * @returns {string} href
 */
export const usePreferredSeriesHrefTemplate = () => {
  const hrefTemplate = useSelector(preferredSeriesHrefTemplateSelector)
  return hrefTemplate
}

const preferredSeriesToastViewedSelector = createSelector(
  [seriesRootSelector],
  seriesRoot => seriesRoot.preferredSeriesToastViewed
)

/**
 * Gets the `seriesRoot.preferredSeriesToastViewed` from redux.
 * @returns {boolean} toastViewed
 */
export const usePreferredSeriesToastViewed = () => {
  const toastViewed = useSelector(preferredSeriesToastViewedSelector)
  return toastViewed
}
