import SERIES_TYPES from './seriesTypes'
import SORTS from './sorts'

const shouldReverseSeriesSort = (seriesType, seriesSort) => {
  switch (seriesType) {
    case SERIES_TYPES.STANDARD: {
      return seriesSort === SORTS.ASC
    }
    default:
      return false
  }
}

export default shouldReverseSeriesSort
