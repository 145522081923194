const toUrlTimestamp = dateTime => {
  const hours = dateTime.getHours()
  const minutes = dateTime.getMinutes()
  const seconds = dateTime.getSeconds()
  const orderedStartPointParts = [
    { postfix: 'h', value: hours },
    { postfix: 'm', value: minutes },
    { postfix: 's', value: seconds }
  ]

  return orderedStartPointParts.reduce((prev, curr) => {
    return curr.value > 0 ? `${prev}${curr.value}${curr.postfix}` : prev
  }, '')
}

export default toUrlTimestamp
